<template>
    <div>
      A CONSERVER ???
        <competition-header v-model="currentCompetition">
        </competition-header>
    </div>
</template>

<script>
import CompetitionHeader from '@/components/CompetitionHeader';

export default {
    components: { CompetitionHeader, },
    data(){
        return {
            currentCompetition: null,
        }
    }
}
</script>
